import styled from 'styled-components';

export const ItemSelectorSide = styled.p`
  border: 1px solid #dedede;
  border-radius: 5px;
  min-height: 30vh;
  max-height: 50vh;
`;

export const ButtonMargin = styled.p`
  margin-bottom: 5px;
`;