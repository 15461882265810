import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import * as S from './ItemSelector.css';

interface ItemSelectorSideProps {
  title: string;
  list: any[];
  onClick: (id: number) => void;
  checked?: boolean;
}

const ItemSelectorSide = ({
  title,
  list,
  onClick,
  checked,
}: ItemSelectorSideProps) => {
  return (
    <>
      <Typography variant="h6">{title}</Typography>
      <S.ItemSelectorSide>
        {list.map((s) => {
          return (
            <ListItem
              role={undefined}
              dense
              divider
              button
              onClick={() => onClick(s.id)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked}
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText primary={s.name} />
            </ListItem>
          );
        })}
      </S.ItemSelectorSide>
    </>
  );
};

interface ItemSelectorProps {
  selectedTitle: string;
  unselectedTitle: string;
  selected: any[];
  unselected: any[];
  setSelectItem: React.Dispatch<React.SetStateAction<any[]>>;
  setUnselectItem: React.Dispatch<React.SetStateAction<any[]>>;
}

const moveItemToList = (
  list: any[],
  itemId: number,
  setMoveFromList: React.Dispatch<React.SetStateAction<any[]>>,
  setMoveToList: React.Dispatch<React.SetStateAction<any[]>>,
) => {
  const toMove = list.find((gk) => gk.id === itemId);
  if (toMove) {
    setMoveToList((prev) => [...prev, toMove]);
    setMoveFromList(() =>
      list.filter((gk: { id: number }) => gk.id !== itemId),
    );
  }
};

const moveAllItems = (
  list: any[],
  setMoveFromList: React.Dispatch<React.SetStateAction<any[]>>,
  setMoveToList: React.Dispatch<React.SetStateAction<any[]>>,
) => {
  setMoveToList((prev) => [...prev, ...list]);
  setMoveFromList([]);
};

const ItemSelector = ({
  selected,
  unselected,
  selectedTitle,
  unselectedTitle,
  setSelectItem,
  setUnselectItem,
}: ItemSelectorProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <ItemSelectorSide
          title={unselectedTitle}
          list={unselected}
          onClick={(id) => {
            moveItemToList(unselected, id, setUnselectItem, setSelectItem);
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <S.ButtonMargin>
            <Button
              title=">>"
              variant="outlined"
              onClick={() => {
                moveAllItems(unselected, setUnselectItem, setSelectItem);
              }}
            >
              {'>>'}
            </Button>
          </S.ButtonMargin>
          <div>
            <Button
              title="<<"
              variant="outlined"
              onClick={() => {
                moveAllItems(selected, setSelectItem, setUnselectItem);
              }}
            >
              {'<<'}
            </Button>
          </div>
        </Box>
      </Grid>
      <Grid item xs={5}>
        <ItemSelectorSide
          title={selectedTitle}
          list={selected}
          onClick={(id) => {
            moveItemToList(selected, id, setSelectItem, setUnselectItem);
          }}
          checked
        />
      </Grid>
    </Grid>
  );
};

export default ItemSelector;
