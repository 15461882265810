import { GroupUser, User,UserOption } from 'modules/Issuers/Issuers.types';

export const canDeleteGroup = (groupUsers: GroupUser[], usersData: User[]) => {
  let canDelete = true;
  groupUsers.forEach((groupUser) => {
    const user = usersData.find((u) => u.id === groupUser.id);
    if (user && user!.groupList.length <= 1) {
      canDelete = false;
    }
  });
  return canDelete;
};

export const canRemoveAllUsers = (selectedUserValues: UserOption[]) => {
  let canRemove = true;
  selectedUserValues.forEach((selectedUser) => {
    if (selectedUser.isFixed) {
      canRemove = false;
    }
  });

  return canRemove;
};

export const isUserRemovable = (
  usersData: User[],
  userID: number,
  groupID?: number,
) => {
  let canRemoveUser = true;
  if (groupID != null) {
    const user = usersData.find((user) => user.id === userID);
    const otherGroups = user?.groupList.filter((group) => group.id !== groupID);
    if (user && (otherGroups == null || otherGroups.length < 1)) {
      canRemoveUser = false;
    }
  }

  return canRemoveUser;
};
