import { createTheme, ThemeOptions } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { Shadows } from '@mui/material/styles/shadows';

export const baseBoxShadow = '0px 5px 5px #00000033';

export const iconsColors = {
  pdf: '#C11E07',
  word: '#2B579A',
  excel: '#227447',
  approved: '#13C846',
};

export const environmentChip = {
  local: 'rgb(232,64,196)',
  test: 'rgb(77,163,9)',
  uat: 'rgb(31,114,211)',
  hotfix: 'rgb(235,133,14)',
  font: 'rgb(255,255,255)',
};
export const graphColor = {
  green: 'rgb(77,163,9)',
  orange: 'rgb(235,133,14)',
};

export const issuesInProcessStateChip = {
  NP: '#E77E3B',
  NF: '#44D400',
  NA: '#ADADAD',
};

export const bondStatusChip = {
  primary: '#152F84',
  secondary: '#CED7F4',
};

export const tableColors = {
  borderColor: '#e0e0e0',
  selectedRow: '#ced7f4',
  hoveredRow: 'rgba(0, 0, 0, 0.04)',
  redRowColor: 'rgba(234, 0, 0, 0.07)',
  darkRedRowColor: 'rgba(234, 0, 0, 0.34)',
  yellowRowColor: 'rgba(234, 168, 0, 0.15)',
  greenRowColor: 'rgb(77,163,9, 0.2)',
  darkGreenRowColor: 'rgb(77,163,9, 0.6)',
  lightYellowRowColor: 'rgb(243, 245, 180)',
  lightGrey: '#d8d8d8',
  lightGrayRowColor: 'rgba(0, 0, 0, 0.12)',
  darkGrayRowColor: 'rgba(0, 0, 0, 0.32)',
  disabledRowColor: 'rgb(246 246 246)',
};

//Default values
//https://material-ui.com/customization/default-theme/

const spacing = 8;

const palette = {
  common: {
    black: '#13161c',
  },
  primary: {
    main: '#3f18aa',
    light: '#6546bb',
    dark: '#2c1076',
  },
  secondary: {
    main: '#e87f38',
    light: '#ec985f',
    dark: '#a25827',
  },
  error: {
    main: '#ff0000',
    dark: '#e80d0d',
  },
  success: {
    main: '#4caf50',
  },
  text: {
    primary: '#13161c',
    secondary: '#0000008a',
  },
  background: {
    default: '#f7f8f6',
    paper: '#fff',
  },
  info: {
    main: '#2196f3',
    contrastText: '#fff',
  },
};

const typography = {
  h1: {
    fontFamily: "'Roboto Condensed', sans-serif",
    fontSize: '40px',
    lineHeight: '38px',
    color: '#13161c',
    letterSpacing: '1px',
    fontWeight: 700,
  },
  h2: {
    fontFamily: "'Roboto Condensed', sans-serif",
    fontSize: '30px',
    lineHeight: '35px',
    color: '#13161c',
    letterSpacing: '1px',
    fontWeight: 700,
  },
  h3: {
    fontFamily: "'Roboto Condensed', sans-serif",
    fontSize: '20px',
    lineHeight: '23px',
    color: '#13161c',
    letterSpacing: '1px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  h4: {
    fontFamily: "'Roboto Condensed', sans-serif",
    fontSize: '20px',
    lineHeight: '23px',
    color: '#13161c',
    letterSpacing: '1px',
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  h5: {
    fontFamily: "'Roboto Condensed', sans-serif",
    fontSize: '17px',
    lineHeight: '20px',
    color: '#0000008a',
    fontWeight: 700,
  },
  h6: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: '17px',
    lineHeight: '25px',
    color: '#13161c',
    fontWeight: 'bold',
  },
  body1: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: '17px',
    lineHeight: '25px',
    color: '#13161c',
  },
  body2: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: '14px',
    lineHeight: '20px',
    color: '#13161c',
    fontWeight: 400,
  },
  button: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: '17px',
    lineHeight: '20px',
    color: '#13161c',
    fontWeight: 500,
    letterSpacing: '0.59px',
  },
} as TypographyOptions;

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1650,
  },
};

const shadows = [
  'none',
  '0px 1px 5px 0px rgba(0,0,0,0.0375),0px 1px 1px 0px rgba(0,0,0,0.02625),0px 2px 1px -1px rgba(0,0,0,0.0225)',
  '0px 1px 7px 0px rgba(0,0,0,0.0375),0px 2px 2px 0px rgba(0,0,0,0.02625),0px 3px 1px -2px rgba(0,0,0,0.0225)',
  '0px 1px 7px 0px rgba(0,0,0,0.0375),0px 3px 4px 0px rgba(0,0,0,0.02625),0px 3px 3px -2px rgba(0,0,0,0.0225)',
  '0px 2px 6px -1px rgba(0,0,0,0.0375),0px 4px 5px 0px rgba(0,0,0,0.02625),0px 1px 10px 0px rgba(0,0,0,0.0225)',
  '0px 3px 7px -1px rgba(0,0,0,0.0375),0px 5px 8px 0px rgba(0,0,0,0.02625),0px 1px 14px 0px rgba(0,0,0,0.0225)',
  '0px 3px 7px -1px rgba(0,0,0,0.0375),0px 6px 10px 0px rgba(0,0,0,0.02625),0px 1px 18px 0px rgba(0,0,0,0.0225)',
  '0px 4px 7px -2px rgba(0,0,0,0.0375),0px 7px 10px 1px rgba(0,0,0,0.02625),0px 2px 16px 1px rgba(0,0,0,0.0225)',
  '0px 5px 7px -3px rgba(0,0,0,0.0375),0px 8px 10px 1px rgba(0,0,0,0.02625),0px 3px 14px 2px rgba(0,0,0,0.0225)',
  '0px 5px 8px -3px rgba(0,0,0,0.0375),0px 9px 12px 1px rgba(0,0,0,0.02625),0px 3px 16px 2px rgba(0,0,0,0.0225)',
  '0px 6px 8px -3px rgba(0,0,0,0.0375),0px 10px 14px 1px rgba(0,0,0,0.02625),0px 4px 18px 3px rgba(0,0,0,0.0225)',
  '0px 6px 9px -4px rgba(0,0,0,0.0375),0px 11px 15px 1px rgba(0,0,0,0.02625),0px 4px 20px 3px rgba(0,0,0,0.0225)',
  '0px 7px 10px -4px rgba(0,0,0,0.0375),0px 12px 17px 2px rgba(0,0,0,0.02625),0px 5px 22px 4px rgba(0,0,0,0.0225)',
  '0px 7px 10px -4px rgba(0,0,0,0.0375),0px 13px 19px 2px rgba(0,0,0,0.02625),0px 5px 24px 4px rgba(0,0,0,0.0225)',
  '0px 7px 11px -4px rgba(0,0,0,0.0375),0px 14px 21px 2px rgba(0,0,0,0.02625),0px 5px 26px 4px rgba(0,0,0,0.0225)',
  '0px 8px 11px -5px rgba(0,0,0,0.0375),0px 15px 22px 2px rgba(0,0,0,0.02625),0px 6px 28px 5px rgba(0,0,0,0.0225)',
  '0px 8px 12px -5px rgba(0,0,0,0.0375),0px 16px 24px 2px rgba(0,0,0,0.02625),0px 6px 30px 5px rgba(0,0,0,0.0225)',
  '0px 8px 13px -5px rgba(0,0,0,0.0375),0px 17px 26px 2px rgba(0,0,0,0.02625),0px 6px 32px 5px rgba(0,0,0,0.0225)',
  '0px 9px 13px -5px rgba(0,0,0,0.0375),0px 18px 28px 2px rgba(0,0,0,0.02625),0px 7px 34px 6px rgba(0,0,0,0.0225)',
  '0px 9px 14px -6px rgba(0,0,0,0.0375),0px 19px 29px 2px rgba(0,0,0,0.02625),0px 7px 36px 6px rgba(0,0,0,0.0225)',
  '0px 10px 15px -6px rgba(0,0,0,0.0375),0px 20px 31px 3px rgba(0,0,0,0.02625),0px 8px 38px 7px rgba(0,0,0,0.0225)',
  '0px 10px 15px -6px rgba(0,0,0,0.0375),0px 21px 33px 3px rgba(0,0,0,0.02625),0px 8px 40px 7px rgba(0,0,0,0.0225)',
  '0px 10px 16px -6px rgba(0,0,0,0.0375),0px 22px 35px 3px rgba(0,0,0,0.02625),0px 8px 42px 7px rgba(0,0,0,0.0225)',
  '0px 11px 16px -7px rgba(0,0,0,0.0375),0px 23px 36px 3px rgba(0,0,0,0.02625),0px 9px 44px 8px rgba(0,0,0,0.0225)',
  '0px 11px 17px -7px rgba(0,0,0,0.0375),0px 24px 38px 3px rgba(0,0,0,0.02625),0px 9px 46px 8px rgba(0,0,0,0.0225)',
] as Shadows;

const components = {
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        border: '1px solid rgba(0,0,0, 0.08)',
        overflow: 'hidden',
      },
      elevation1: {
        boxShadow: baseBoxShadow,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: 14,
      },
      MuiFormLabel: {
        root: {
          '&$error': {
            color: 'rgba(0, 0, 0, 0.54)',
          },
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        '& > div > div[class*="control"]:hover': {
          boxShadow: 'none',
          background:
            'linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%) 0px 100% / 100% 1px no-repeat',
          transition: 'background-size 0.2s ease 0s',
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        boxShadow: baseBoxShadow,
        border: '1px solid rgba(0,0,0,0.05)',
        borderRadius: '4px',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        height: '36px',
        fontSize: 14,
      },
      sizeSmall: {
        height: 'auto',
      },
      containedSecondary: {
        color: palette.info.contrastText,
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        textTransform: 'uppercase',
        '& button': {
          marginLeft: spacing,
          '& svg': {
            marginRight: spacing,
          },
        },
      },
      subheader: {
        textTransform: 'initial',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        marginRight: '0.5rem',
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        '&.MuiCheckbox-root.MuiCheckbox-indeterminate': {
          color: palette.text.secondary,
        },
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      maxWidthXl: {
        paddingLeft: 45,
        paddingRight: 45,
        '@media (max-width:600px)': {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  },
  MuiGrid: {
    styleOverrides: {
      'spacing-xs-2': {
        '@media (max-width:600px)': {
          width: '100%',
          margin: '0rem',
          MuiGrid: {
            item: {
              padding: '0rem',
            },
          },
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        padding: '0.5rem',
        fontSize: 'inherit', // or unset
      },
      colorInherit: {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      underline: {
        '&$error:after': {
          borderBottom: 'none',
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'standard',
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        fontSize: 14,
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: palette.common.black,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      formControl: {
        color: 'rgba(0, 0, 0, 0.54)',
      },
      root: {
        marginTop: '0rem',
        marginBottom: '-0.75rem',
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        whiteSpace: 'nowrap',
        borderCollapse: 'separate',
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        '&&': {
          '& .MuiTextField-root': {
            padding: 0,
          },
        },
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        'overflow-y': 'hidden',
        '&&': {
          '& > div:first-child': {
            padding: 0,
          },
          '& .MuiTypography-caption': {
            display: 'block',
          },
          '& .MuiInputBase-root': {
            margin: 0,
          },
        },
      },

      displayedRows: {
        '&&': {
          '&:last-of-type': {
            display: 'none',
          },
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        padding: 0,
        '&$selected, &$selected:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: spacing,
      },
      head: {
        color: 'rgba(0,0,0,0.56)',
        fontWeight: 'bold',
        fontFamily: 'Roboto Condensed',
        fontSize: 16,
        zIndex: 3,
      },
      paddingCheckbox: {
        paddingRight: '4px!important',
        paddingLeft: '4px!important',
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {},
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        height: '5px',
        borderRadius: '20px',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'capitalize',
        paddingTop: 6,
        paddingRight: 12,
        paddingBottom: 6,
        paddingLeft: 12,
        '@media (min-width:600px)': {
          minWidth: 160,
        },
      },
      wrapper: {
        flexDirection: 'row-reverse',
        justifyContent: 'space-evenly',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: 14,
        textTransform: 'uppercase',
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        display: 'block',
        position: 'relative',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      icon: {
        position: 'sticky',
        top: 'calc(50% - 9px)',
        right: -4,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: '12px',
        whiteSpace: 'pre-wrap',
        maxWidth: '600px',
        overflowY: 'auto',
        maxHeight: '60vh',
        backgroundColor: 'rgba(90,90,90, 0.90)',
      },
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        '&.Mui-completed': {
          color: 'rgba(0, 0, 0, 0.38)',
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled, .MuiSwitch-switchBase.Mui-disabled, .MuiSwitch-switchBase':
          {
            color: palette.primary.main,
          },
        '& .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track': {
          backgroundColor: palette.common.black,
        },
      },
    },
  },
  MuiStepper: {
    styleOverrides: {
      root: {
        padding: '24px',
        border: '1px solid rgba(0,0,0, 0.08)',
        '& .MuiStepLabel-label.Mui-disabled': {
          color: palette.text.secondary,
        },
      },
    },
  },
};

const theme = createTheme({
  typography,
  breakpoints,
  spacing,
  palette,
  components,
  shadows,
} as ThemeOptions);

export default createTheme(theme);

// Generated using http://mcg.mbitson.com/#!?mcgpalette0=%233f18aa#%2F
export const primaryColorShades = [
  '#e8e3f5',
  '#c5bae6',
  '#9f8cd5',
  '#795dc4',
  '#5c3bb7',
  '#3f18aa',
  '#3915a3',
  '#311199',
  '#290e90',
  '#1b087f',
  '#b8aeff',
  '#8b7bff',
  '#5e48ff',
  '#472fff',
];
