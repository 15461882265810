import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import { FormControlLabel, Switch } from '@mui/material';
import {
  Button,
  ConfirmDialog,
  Container,
  SuccessToast,
} from '@nordictrustee/nt-ui-library';
import { ISSUERS } from 'router/url';
import {
  Group,
  User,
  UserDeleteDTO,
  UserGroupWithIssuer,
} from 'modules/Issuers/Issuers.types';
import { IssuersContext } from 'modules/Issuers/IssuersContext';
import { handleException } from 'utils/errorHandlingUtils';
import { useToggle } from 'utils/hooks';
import AxiosPromiseGeneric from 'utils/types/AxiosPromiseGeneric';
import UserEditForm from './comopnents/UserEditForm';
import UsersDeleteForm from './comopnents/UsersDeleteFrom/UsersDeleteForm';
import UsersTable from './comopnents/UsersTable';
import UserStepper from './comopnents/UserStepper';
import * as api from './api';

interface Props {
  usersData: User[];
  isLoadingUsers: boolean;
  getUsers: AxiosPromiseGeneric<User[]>;
  getGroups: AxiosPromiseGeneric<Group[]>;
  groups: Group[];
}

const UserList = ({
  usersData,
  isLoadingUsers,
  getUsers,
  getGroups,
  groups,
}: Props) => {
  const { push } = useHistory();
  const [showAllForIssuer, setShowAllForIssuer] = useState(true);
  const [editedItem, setEditedItem] = useState<User>();
  const [deletedId, setDeletedId] = useState<number>();
  const [sendUserId, setSendUserId] = useState<number>();
  const [sendUsername, setSendUsername] = useState<string>();
  const [sendUserInviteDate, setSendUserInviteDate] = useState<string>();
  const {
    issuerCompanyFunctionID,
    groupID,
    filterStringFromQuery,
    pageFromQuery,
    pageSizeFromQuery,
  } = useContext(IssuersContext);

  const [
    isUserAddFormOpen,
    handleOpenUserAddForm,
    handleCloseUserAddForm,
  ] = useToggle();
  const [
    isUserEditFormOpen,
    handleOpenUserEditForm,
    handleCloseUserEditForm,
  ] = useToggle();

  const [
    isDeleteDialogOpen,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
  ] = useToggle();

  useEffect(() => {
    if (groupID) {
      setShowAllForIssuer(false);
    } else {
      setShowAllForIssuer(true);
    }
  }, [groupID]);

  useEffect(() => {
    if (issuerCompanyFunctionID) {
      setShowAllForIssuer(true);
    }
  }, [issuerCompanyFunctionID]);

  const [
    isSendUserInvitationDialogOpen,
    handleOpenSendUserInvitationDialog,
    handleCloseSendUserInvitationDialog,
  ] = useToggle();

  const { deleteUser } = api.useDeleteUser(deletedId!);

  const {
    isLoadingSendUserInvitation,
    sendUserInvitation,
  } = api.useSendUserInvitation(sendUserId);

  const handleEditOpen = (data: User) => {
    setEditedItem(data);
    handleOpenUserEditForm();
  };

  const handleAddOpen = () => {
    handleOpenUserAddForm();
  };

  const handleDeleteOpen = (data: User) => {
    setDeletedId(data.id);
    setEditedItem(data);
    handleOpenDeleteDialog();
  };

  const handleDelete = async (
    user: User,
    userGroupsToRemove: UserGroupWithIssuer[],
  ) => {
    if (
      user.groupList.length === userGroupsToRemove.length &&
      !window.confirm(
        `You have removed all groups from the user. This will also delete the user. Are you sure you want to delete ${user.username}`,
      )
    ) {
      return;
    }

    try {
      const deleteUserDTO: UserDeleteDTO = {
        groups: userGroupsToRemove.map((m) => {
          return { groupID: m.id };
        }),
      };
      await deleteUser({ data: deleteUserDTO });
      getGroups();
      getUsers();
      handleCloseDeleteDialog();
      toast.success(<SuccessToast message="User access has been removed!" />);
    } catch (e: any) {
      handleException(e);
    }
  };

  const handleSwitch = () => {
    setShowAllForIssuer(!showAllForIssuer);
    push(
      `${ISSUERS}?issuerCompanyFunctionID=${issuerCompanyFunctionID}&groupId=&page=${pageFromQuery}&pageSize=${pageSizeFromQuery}&query=${filterStringFromQuery}`,
    );
  };

  const handleSendUserInvitationConfirmOpen = (
    id: number,
    username: string,
    inviteSentDate: string,
  ) => {
    setSendUserId(id);
    setSendUsername(username);
    setSendUserInviteDate(inviteSentDate);
    handleOpenSendUserInvitationDialog();
  };

  const handleSendInvitation = async () => {
    try {
      await sendUserInvitation();
      toast.success(
        <SuccessToast
          message={`Invitation has been sent to ${sendUsername}`}
        />,
      );
    } catch (e: any) {
      handleException(e);
    }
  };

  return (
    <>
      <Container
        title="Users"
        headerControls={
          <>
            <FormControlLabel
              control={
                <>
                  <Switch checked={showAllForIssuer} color="primary" />
                </>
              }
              onClick={handleSwitch}
              label="Show all users for the issuer"
            />
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddOpen}
              data-testid="add-user-button"
            >
              Add User
            </Button>
          </>
        }
      >
        <UsersTable
          actions={[
            ({ id, username, inviteSentDate }) => ({
              icon: () => <SendIcon color="action" />,
              tooltip: 'Send Invitation',
              onClick: () =>
                handleSendUserInvitationConfirmOpen(
                  id,
                  username,
                  inviteSentDate,
                ),
            }),
            (data) => ({
              icon: () => <EditIcon color="action" />,
              tooltip: 'Edit',
              onClick: () => handleEditOpen(data),
            }),
            (data) => ({
              icon: () => <DeleteIcon color="action" />,
              tooltip: 'Delete',
              onClick: () => handleDeleteOpen(data),
            }),
          ]}
          showAllForIssuer={showAllForIssuer}
          usersData={usersData}
          isLoadingUsers={isLoadingUsers}
          getUsers={getUsers}
        />
      </Container>
      {isUserAddFormOpen && (
        <UserStepper
          open={isUserAddFormOpen}
          onClose={handleCloseUserAddForm}
          groups={groups}
          getGroups={getGroups}
          getUsers={getUsers}
        />
      )}
      {isSendUserInvitationDialogOpen && (
        <ConfirmDialog
          title="Confirm invitation"
          open={isSendUserInvitationDialogOpen}
          message={`Do you want to ${
            sendUserInviteDate ? 're-' : ''
          }send invitation to ${sendUsername}? 
          ${sendUserInviteDate ? `(Last sent: ${sendUserInviteDate})` : ''}`}
          submitText="Send"
          onCancel={handleCloseSendUserInvitationDialog}
          onSubmit={handleSendInvitation}
          isSubmitDisabled={isLoadingSendUserInvitation}
        />
      )}
      {isUserEditFormOpen && (
        <UserEditForm
          handleClose={handleCloseUserEditForm}
          user={editedItem}
          groups={groups}
          getGroups={getGroups}
          getUsers={getUsers}
        />
      )}
      {isDeleteDialogOpen && (
        <UsersDeleteForm
          open={isDeleteDialogOpen}
          onCancel={handleCloseDeleteDialog}
          onSubmit={handleDelete}
          user={editedItem}
          issuerCompanyFunctionID={issuerCompanyFunctionID}
        />
      )}
    </>
  );
};

export default UserList;
