import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@mui/material/styles';
import { ToastProvider } from '@nordictrustee/nt-ui-library';
import { Routes } from 'router/Routes';
import defaultTheme from 'themes';
import { EnvironmentName } from 'utils/types/EnvironmentName';
import './index.css';

type Props = {
  pca: IPublicClientApplication;
};

const App = ({ pca }: Props) => {
  useEffect(() => {
    const { ENVIRONMENT_NAME } = window._env_;

    if (ENVIRONMENT_NAME && ENVIRONMENT_NAME !== EnvironmentName.prod) {
      const favicon = document.getElementById('favicon');
      // favicon-test.ico | favicon-uat.ico | favicon-hotfix.ico
      favicon?.setAttribute('href', `/favicon-${ENVIRONMENT_NAME}.ico?v=1`);
    }
  }, []);

  return (
      <MsalProvider instance={pca}>
        <ToastProvider />
        <ThemeProvider theme={defaultTheme.theme}>
          <Router>
            <Routes />
          </Router>
        </ThemeProvider>
      </MsalProvider>
  );
};

export default App;
