import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-v6';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import {
  Button,
  Dialog,
  OutlinedButton,
  SuccessToast,
} from '@nordictrustee/nt-ui-library';
import { ISSUERS } from 'router/url';
import {
  IssuerAddFormFields,
  IssuersResult,
} from 'modules/Issuers/Issuers.types';
import { IssuersContext } from 'modules/Issuers/IssuersContext';
import { confirmClose } from 'utils/confirmClose';
import { handleException } from 'utils/errorHandlingUtils';
import AxiosPromiseGeneric from 'utils/types/AxiosPromiseGeneric';
import { PageableResponse } from 'utils/types/PageableResponse';
import * as issuerDataApi from '../../../../api';
import * as api from '../../api';
import IssuerFormGroupStep from './components/IssuerFormGroupStep';
import IssuerFormIssuerStep from './components/IssuerFormIssuerStep';
import IssuerFormUsernameStep from './components/IssuerFormUsernameStep';
import IssuerFormUserStep from './components/IssuerFormUserStep';
import * as S from './IssuerStepper.css';

type Props = {
  open: boolean;
  onClose: () => void;
  getAssignedIssuers: AxiosPromiseGeneric<PageableResponse<IssuersResult[]>>;
};

const stepsLabels = ['Add Issuer', 'Add Group', 'Add Username', 'Add User'];

const IssuerStepper = ({ open, onClose, getAssignedIssuers }: Props) => {
  const { push } = useHistory();
  const methods = useForm({ mode: 'onBlur' });
  const { watch, errors, handleSubmit, formState } = methods;

  const [activeStep, setActiveStep] = useState(0);
  const [isStepperDirty, setStepperDirty] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [compiledForm, setCompiledForm] = useState<IssuerAddFormFields>({
    issuerCompanyFunctionID: '',
    groupName: '',
    remark: '',
    username: '',
    firstName: '',
    lastName: '',
  });

  const {
    filterStringFromQuery,
    pageFromQuery,
    pageSizeFromQuery,
  } = useContext(IssuersContext);

  const form = watch();

  const { postIssuer } = api.usePostIssuer();
  const { validateUser } = issuerDataApi.useValidateUser(form.username);

  const getStepContent = (step: number, formContent?: IssuerAddFormFields) => {
    switch (step) {
      case 0:
        return <IssuerFormIssuerStep formContent={formContent} />;
      case 1:
        return <IssuerFormGroupStep formContent={formContent} />;
      case 2:
        return <IssuerFormUsernameStep formContent={formContent} />;
      case 3:
        return <IssuerFormUserStep formContent={formContent} />;
      default:
        return 'Unknown step';
    }
  };

  useEffect(() => {
    //Select on the first step is required, so it's enough to make stepperDitry
    if (activeStep === 0) {
      setStepperDirty(formState.isDirty);
    }
  }, [activeStep, formState.isDirty]);

  const isNewUser = async () => {
    setIsLoadingNext(true);
    let result = await validateUser();

    let userInfo = result.data?.userInfo;
    setCompiledForm({
      ...compiledForm,
      username: form.username,
      firstName: userInfo ? userInfo.firstName : '',
      lastName: userInfo ? userInfo.lastName : '',
    });
    setIsLoadingNext(false);
    return true;
  };

  const handleNext = handleSubmit(async () => {
    let canContinue = true;
    switch (activeStep) {
      case 0:
        setCompiledForm({
          ...compiledForm,
          issuerCompanyFunctionID: form.issuerCompanyFunctionID,
        });
        canContinue = true;
        break;
      case 1:
        setCompiledForm({
          ...compiledForm,
          groupName: form.groupName,
          remark: form.remark,
        });
        canContinue = true;
        break;
      case 2:
        setCompiledForm({
          ...compiledForm,
          username: form.username,
        });
        canContinue = await isNewUser();
        break;
      case 3:
        setCompiledForm({
          ...compiledForm,
          firstName: form.firstName,
          lastName: form.lastName,
        });
        canContinue = false;
        onSave({
          ...compiledForm,
          firstName: form.firstName,
          lastName: form.lastName,
        });
        break;
      default:
        return 'not a valid step';
    }
    if (canContinue) {
      setActiveStep((prevActiveStep) => ++prevActiveStep);
    }
  });

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => --prevActiveStep);
      setCompiledForm(form as IssuerAddFormFields);
    }
  };

  const handleConfirmClose = () => {
    confirmClose(isStepperDirty, onClose);
  };

  useEffect(() => {
    if (open) {
      setActiveStep(0);
      setCompiledForm({
        issuerCompanyFunctionID: '',
        groupName: '',
        remark: '',
        username: '',
        firstName: '',
        lastName: '',
      });
    }
  }, [open]);

  const onSave = async (data: IssuerAddFormFields) => {
    try {
      const formData = {
        group: {
          name: data.groupName,
          issuerCompanyFunctionID: data.issuerCompanyFunctionID,
          remark: data.remark,
        },
        user: {
          username: data.username,
          firstName: data.firstName,
          lastName: data.lastName,
        },
      };
      const response = await postIssuer({ data: formData });
      if (response?.data?.groupID) {
        push(
          `${ISSUERS}?issuerCompanyFunctionID=${data.issuerCompanyFunctionID}&groupId=${response.data.groupID}&page=${pageFromQuery}&pageSize=${pageSizeFromQuery}&query=${filterStringFromQuery}`,
        );
      }
      getAssignedIssuers();
      toast.success(<SuccessToast message="Issuer has been saved" />);
      onClose();
    } catch (e: any) {
      handleException(e);
    }
  };

  const continueDisabled = !!Object.values(errors).length;

  return (
    <Dialog
      open={open}
      onClose={handleConfirmClose}
      fullWidth
      maxWidth="sm"
      title="Add Issuer"
      dialogActions={
        <>
          <Button
            onClick={handleNext}
            data-testid="continue-button"
            disabled={continueDisabled}
            isLoading={isLoadingNext}
          >
            {activeStep === stepsLabels.length - 1 ? 'save' : 'continue'}
          </Button>
          <OutlinedButton
            onClick={handleConfirmClose}
            data-testid="cancel-button"
          >
            cancel
          </OutlinedButton>
          <Button
            onClick={handleBack}
            data-testid="back-button"
            disabled={activeStep === 0}
          >
            back
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <S.Container>
          <Stepper activeStep={activeStep}>
            {stepsLabels.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box mt={2}>{getStepContent(activeStep, compiledForm)}</Box>
        </S.Container>
      </FormProvider>
    </Dialog>
  );
};

export default IssuerStepper;
