import { GROUPS, USER_MANAGEMENT, USERS } from 'router/url';
import { GroupUser } from 'modules/Issuers/Issuers.types';
import { useAxios } from 'utils/hooks/useAxios';
import { PageableResponse } from 'utils/types/PageableResponse';

export const usePostGroup = () => {
  const [, postGroup] = useAxios(
    {
      url: `${USER_MANAGEMENT}${GROUPS}`,
      method: 'POST',
    },
    { manual: true },
  );
  return { postGroup };
};

export const usePutGroup = (groupId?: number) => {
  const [, putGroup] = useAxios(
    {
      url: `${USER_MANAGEMENT}${GROUPS}/${groupId}`,
      method: 'PUT',
    },
    { manual: true },
  );
  return { putGroup };
};

export const useDeleteGroup = (deletedItemId: number) => {
  const [{ loading: isLoadingDeleteGroup }, deleteGroup] = useAxios(
    { url: `${USER_MANAGEMENT}${GROUPS}/${deletedItemId}`, method: 'DELETE' },
    { manual: true },
  );
  return { isLoadingDeleteGroup, deleteGroup };
};

export const useGetFilteredUsers = (
  userIDsToExclude: number[],
  searchQuery?: string,
) => {
  let excludedIDsQuery = '';

  userIDsToExclude.forEach((id) => {
    excludedIDsQuery = excludedIDsQuery.concat(`&userIDsToExclude=${id}`);
  });
  const [
    { data: filteredUsersData, loading: isLoadingFilteredUsers },
    getFilteredUsers,
  ] = useAxios<PageableResponse<GroupUser[]>>(
    `${USER_MANAGEMENT}${USERS}?filterString=${searchQuery}&pageSize=15${excludedIDsQuery}`,
    { manual: true },
  );
  return {
    filteredUsersData,
    isLoadingFilteredUsers,
    getFilteredUsers,
  };
};
