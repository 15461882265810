import { ReactNode } from 'react';
import * as S from './Toolbar.css';

interface Props {
  children?: ReactNode;
}

const Toolbar = (props: Props) => {
  return <S.Toolbar>{props.children}</S.Toolbar>;
};

export default Toolbar;
