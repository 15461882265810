import React, { useContext } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { IssuersContext } from 'modules/Issuers/IssuersContext';
import GroupList from './components/GroupList';
import IssuersTable from './components/IssuersTable';
import UserList from './components/UserList';
import * as api from './api';

const IssuerData = () => {
  const { issuerCompanyFunctionID } = useContext(IssuersContext);
  const { usersData, isLoadingUsers, getUsers } = api.useGetUsers(
    issuerCompanyFunctionID,
  );
  const { groupsData, isLoadingGroups, getGroups } = api.useGetGroups(
    issuerCompanyFunctionID,
  );

  return (
    <>
      <Container maxWidth="xl">
        <Box mb={3}>
          <Typography variant="h2">Issuers</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={5}>
            <IssuersTable />
          </Grid>
          <Grid item xs={12} lg={7}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <GroupList
                  usersData={usersData}
                  getUsers={getUsers}
                  groupsData={groupsData}
                  isLoadingGroups={isLoadingGroups}
                  getGroups={getGroups}
                />
              </Grid>
              <Grid item xs={12}>
                <UserList
                  usersData={usersData}
                  isLoadingUsers={isLoadingUsers}
                  getUsers={getUsers}
                  getGroups={getGroups}
                  groups={groupsData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default IssuerData;
