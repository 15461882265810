import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Column } from '@material-table/core';
import {
  BoxEmpty,
  Table,
} from '@nordictrustee/nt-ui-library';
import { ISSUERS } from 'router/url';
import { Group } from 'modules/Issuers/Issuers.types';
import { IssuersContext } from 'modules/Issuers/IssuersContext';
import { firstColPadding } from 'utils/constants';
import { selectedRowStyle } from 'utils/selectedRowStyle';
import { ActionsGeneric } from 'utils/types/ActionsGeneric';
import AxiosPromiseGeneric from 'utils/types/AxiosPromiseGeneric';

const columns: Column<Group>[] = [
  {
    field: 'name',
    title: 'Name',
    render: (item) => (
      <Table.ColumnWithTooltip title={item.name} inheritWidth>{item.name}</Table.ColumnWithTooltip>
    ),
    cellStyle: { ...firstColPadding.cellStyle, width: '300px' },
    headerStyle: { ...firstColPadding.headerStyle },
  },
  {
    field: 'remark',
    title: 'Remark',
    render: (item) => (
      <Table.ColumnWithTooltip title={item.remark} inheritWidth>{item.remark}</Table.ColumnWithTooltip>
    ),
  },
];

interface Props {
  actions: ActionsGeneric<Group>;
  groupsData: Group[];
  isLoadingGroups: boolean;
  getGroups: AxiosPromiseGeneric<Group[]>;
}

const GroupsTable = ({
  actions,
  groupsData,
  isLoadingGroups,
  getGroups,
}: Props) => {
  const { push } = useHistory();
  const {
    issuerCompanyFunctionID,
    groupID,
    filterStringFromQuery,
    pageFromQuery,
    pageSizeFromQuery,
  } = useContext(IssuersContext);

  useEffect(() => {
    if (issuerCompanyFunctionID > 0) {
      getGroups();
    }
  }, [getGroups, issuerCompanyFunctionID]);

  return issuerCompanyFunctionID ? (
    <Table.Root<Group>
      columns={columns}
      data={groupsData}
      isLoading={isLoadingGroups}
      disableDefaultSearchAndFilter
      options={{
        paging: false,
        search: false,
        toolbar: false,
        maxBodyHeight: '20vh',
        minBodyHeight: '20vh',
        rowStyle: (rowData: Group) => selectedRowStyle(rowData.id, groupID),
      }}
      actions={actions}
      onRowClick={(_: any, rowData: Group | undefined) => {
        push(
          `${ISSUERS}?issuerCompanyFunctionID=${issuerCompanyFunctionID}&groupId=${rowData!.id}&page=${pageFromQuery}&pageSize=${pageSizeFromQuery}&query=${filterStringFromQuery}`,
        );
      }}
    />
  ) : (
    <BoxEmpty height="20vh" message="Select Issuer for the details" />
  );
};

export default GroupsTable;
