// General
export const URL_ROOT = '/';
export const URL_LOGIN = '/login';
export const USER_MANAGEMENT = '/user-management';

// Issuers
export const ISSUERS = '/issuers';
export const ISSUERS_SEARCH = '/issuers/search';

// Groups
export const GROUPS = '/groups';

// Users
export const USERS = '/users';
