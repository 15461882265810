import { toast } from 'react-toastify';
import { ErrorToast } from '@nordictrustee/nt-ui-library';
import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { loginRequest, msalInstance,scopes } from 'msalConfig';
import { getBrowserSessionId } from './getBrowserSessionId';

const axiosInstance = axios.create({
  baseURL: '/api',
});

const browserSessionIdHeader = 'X-NT-Browser-Session-Id';
const browserOriginHeader = 'X-NT-Browser-Request-Origin';
const browserOriginNameHeader = 'X-NT-Browser-Request-Origin-Name';

axiosInstance.interceptors.request.use(
  async (config) => {
    const browserSessionId = getBrowserSessionId(browserSessionIdHeader);
    const browserRequestOrigin =
      window.location.origin + window.location.pathname;


    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.'
      );
    }
    return msalInstance
      .acquireTokenSilent({
        scopes: scopes,
        account: account
      })
      .then((response) => {
        config.headers.set({
          ...config.headers,
          Authorization: `Bearer ${response.accessToken}`,
          [browserSessionIdHeader]: browserSessionId,
          [browserOriginHeader]: browserRequestOrigin,
          [browserOriginNameHeader]: 'Issuer portal user management'
        });
        return config;
      })
      .catch((error) => {
        msalInstance.logoutRedirect();
        return error;
      });
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error?.response?.status === 401) {
      msalInstance.loginRedirect(loginRequest);
    }

    const message =
      error?.response?.data?.message ||
      'Unknown error has occurred. Please contact Tillit team (Slack channel "#dev-support").';
    const details = error?.response?.data?.details;
    // do not show error if response is cancelled (eg. on fast tabs switching)
    if (!axios.isCancel(error) && error?.response) {
      toast.error(<ErrorToast message={message} />, {
        autoClose: false,
      });
    }
    if (details) {
      console.error(`API response error details: ${details}`);
    }
    //Reject is necessary here for proper error handling of requests
    return Promise.reject(error);
  },
);

export const useAxios = makeUseAxios({
  axios: axiosInstance,
});
