import React, { createContext, ReactNode, useMemo } from 'react';
import { useQuery } from 'utils/hooks';

export type Props = {
  children: ReactNode;
};

export type ProviderValue = {
  issuerCompanyFunctionID: number;
  groupID: number;
  filterStringFromQuery: string;
  pageFromQuery: number;
  pageSizeFromQuery: number;
};

export const IssuersContext = createContext<ProviderValue>({
  issuerCompanyFunctionID: 0,
  groupID: 0,
  filterStringFromQuery: '',
  pageFromQuery: 0,
  pageSizeFromQuery: 25,
});

export const IssuersProvider = ({ children }: Props) => {
  const query = useQuery();
  const issuerCompanyFunctionID = useMemo(() => Number(query.get('issuerCompanyFunctionID')) || 0, [query]);
  const groupID = useMemo(() => Number(query.get('groupId')) || 0, [query]);
  const filterStringFromQuery = useMemo(() => query.get('query') || '', [
    query,
  ]);
  const pageFromQuery = useMemo(() => Number(query.get('page')) || 0, [query]);
  const pageSizeFromQuery = useMemo(() => Number(query.get('pageSize')) || 25, [
    query,
  ]);

  const value = useMemo(
    () => ({
      issuerCompanyFunctionID,
      groupID,
      filterStringFromQuery,
      pageFromQuery,
      pageSizeFromQuery,
    }),
    [
      filterStringFromQuery,
      groupID,
      issuerCompanyFunctionID,
      pageFromQuery,
      pageSizeFromQuery,
    ],
  );

  return (
    <IssuersContext.Provider value={value}>{children}</IssuersContext.Provider>
  );
};
