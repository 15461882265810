import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Column, Components, MTableToolbar } from '@material-table/core';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import {
  Button,
  Container,
  Table,
} from '@nordictrustee/nt-ui-library';
import { ISSUERS } from 'router/url';
import { IssuersResult } from 'modules/Issuers/Issuers.types';
import { IssuersContext } from 'modules/Issuers/IssuersContext';
import { firstColPadding } from 'utils/constants';
import { usePaginatedTable, useToggle } from 'utils/hooks';
import { selectedRowStyle } from 'utils/selectedRowStyle';
import { ToolbarComponentType } from 'utils/types/ToolbarComponentType';
import IssuerStepper from './components/IssuerStepper/IssuerStepper';
import * as api from './api';
import * as S from './IssuersTable.css';

const columns: Column<IssuersResult>[] = [
  {
    field: 'issuer',
    title: 'Issuer',
    render: (item) => (
      <Table.ColumnWithTooltip title={item.name}>{item.name}</Table.ColumnWithTooltip>
    ),
    cellStyle: {
      ...firstColPadding.cellStyle,
      width: '300px',
      maxWidth: '300px',
    },
    headerStyle: { ...firstColPadding.headerStyle },
  },
  {
    field: 'country',
    title: 'Country',
    render: (item) => (
      <Table.ColumnWithTooltip title={item.country}>{item.country}</Table.ColumnWithTooltip>
    ),
  },
];

const IssuersTable = () => {
  const { push } = useHistory();
  const {
    issuerCompanyFunctionID,
    groupID,
    filterStringFromQuery,
    pageFromQuery,
    pageSizeFromQuery,
  } = useContext(IssuersContext);

  const [search, setSearch] = useState(filterStringFromQuery);

  const [isFormOpen, handleOpenFormModal, handleCloseFormModal] = useToggle();

  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePaginatedTable(pageSizeFromQuery, pageFromQuery);

  const handleSearch = useCallback(
    (searchText: string) => {
      setSearch(searchText);
      handleChangePage(0);
    },
    [handleChangePage],
  );

  const {
    assignedIssuersData,
    isLoadingAssignedIssuers,
    getAssignedIssuers,
  } = api.useGetAssignedIssuers(page, rowsPerPage, search);

  const params = useMemo(
    () => ({
      groupId: groupID.toString(),
      page: page.toString(),
      pageSize: rowsPerPage.toString(),
      query: search,
    }),
    [groupID, page, rowsPerPage, search],
  );
  const searchQuery = useMemo(() => new URLSearchParams(params), [params]);

  useEffect(() => {
    push(
      `${ISSUERS}?issuerCompanyFunctionID=${issuerCompanyFunctionID}&${searchQuery}`,
    );
  }, [
    groupID,
    issuerCompanyFunctionID,
    page,
    push,
    rowsPerPage,
    search,
    searchQuery,
  ]);

  useEffect(() => {
    getAssignedIssuers();
  }, [getAssignedIssuers]);

  const ToolbarComponent: ToolbarComponentType<IssuersResult> = useMemo(
    () => (props) => (
      <S.Header>
        <Box display="flex" alignItems="center" paddingLeft={0}>
          <MTableToolbar
            {...props}
            classes={{
              searchField: 'material-table-search-field',
            }}
          />
        </Box>
        <Button
          startIcon={<AddIcon />}
          onClick={handleOpenFormModal}
          data-testid="container-storybook-icon"
        >
          Add Issuer
        </Button>
      </S.Header>
    ),
    [handleOpenFormModal],
  );

  const tableComponents: Components = useMemo(
    () => ({
      Toolbar: (props) => <ToolbarComponent {...props} />,
    }),
    [ToolbarComponent],
  );

  return (
    <>
      <Container isHeader={false} noBottomBorder={true}>
        <Table.Root<IssuersResult>
          components={tableComponents}
          columns={columns}
          data={assignedIssuersData?.results ?? []}
          isLoading={isLoadingAssignedIssuers}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          disableDefaultSearchAndFilter
          options={{
            searchAutoFocus: true,
            searchText: search,
            actionsColumnIndex: -1,
            searchFieldAlignment: 'left',
            pageSize: rowsPerPage,
            pageSizeOptions: [10, 25, 50, 100],
            debounceInterval: 300,
            maxBodyHeight: '70vh',
            minBodyHeight: '70vh',
            searchFieldStyle: { width: '260px' },
            rowStyle: (rowData: IssuersResult) =>
              selectedRowStyle(rowData.id, issuerCompanyFunctionID),
          }}
          onSearchChange={handleSearch}
          localization={{
            toolbar: {
              searchPlaceholder: 'Search for issuer',
            },
          }}
          page={page}
          totalCount={assignedIssuersData?.count}
          onRowClick={(_, rowData: IssuersResult|undefined) => {
            push(`${ISSUERS}?issuerCompanyFunctionID=${rowData!.id}&${searchQuery}`);
          }}
        />
      </Container>
      {isFormOpen && (
        <IssuerStepper
          open={isFormOpen}
          onClose={handleCloseFormModal}
          getAssignedIssuers={getAssignedIssuers}
        />
      )}
    </>
  );
};

export default IssuersTable;
