import { ReactNode, useMemo } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ErrorBoundary, Preloader } from '@nordictrustee/nt-ui-library';
import Login from 'modules/Login';
import Header from './components/Header';
import LayoutContainer from './components/LayoutContainer';

interface Props {
  children: ReactNode;
}

const Layout = (props: Props) => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const showPages = useMemo(
    () =>
      inProgress !== InteractionStatus.None ? (
        <Preloader />
      ) : isAuthenticated ? (
        <>{props.children}</>
      ) : (
        <Login />
      ),
    [inProgress, isAuthenticated, props.children],
  );

  return (
    <>
      <Header isAuthenticated={isAuthenticated} />
      <ErrorBoundary>
        <LayoutContainer>{showPages}</LayoutContainer>
      </ErrorBoundary>
    </>
  );
};

export default Layout;
