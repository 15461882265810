import { ReactText } from 'react';
import theme from 'themes';
import { tableRowHeight } from 'utils/constants';

export const selectedRowStyle = (rowId: Number, itemId?: ReactText) =>
  rowId === Number(itemId)
    ? {
        backgroundColor: theme.tableColors.selectedRow,
        height: tableRowHeight,
      }
    : { height: tableRowHeight };
