import React, { useMemo } from 'react';
import { Chip } from '@mui/material';
import theme from 'themes';
import { EnvironmentName } from 'utils/types/EnvironmentName';

interface Props {
  environmentName: EnvironmentName;
}

const EnvironmentChip = ({ environmentName }: Props) => {
  const chipBackgroundColor = useMemo(() => {
    switch (environmentName) {
      case EnvironmentName.test: {
        return theme.environmentChip.test;
      }
      case EnvironmentName.uat: {
        return theme.environmentChip.uat;
      }
      case EnvironmentName.hotfix: {
        return theme.environmentChip.hotfix;
      }
    }
  }, [environmentName]);

  return (
    <Chip
      style={{
        backgroundColor: chipBackgroundColor,
        textTransform: 'uppercase',
        color: theme.environmentChip.font,
        margin: '0 0.5rem',
        fontWeight: 500,
      }}
      label={environmentName}
    />
  );
};

export default EnvironmentChip;
