import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form-v6';
import { TextInput } from '@nordictrustee/nt-ui-library';
import { IssuerAddFormFields } from 'modules/Issuers/Issuers.types';

interface Props {
  formContent?: IssuerAddFormFields;
}

const IssuerFormUserStep = ({ formContent }: Props) => {
  const methods = useFormContext();
  const { errors, control, reset } = methods;

  useEffect(() => {
    reset(formContent);
  }, [formContent, reset]);

  return (
    <>
      <TextInput
        control={control}
        errors={errors}
        data-testid="firstName"
        name="firstName"
        label="First Name"
        isRequired
        autoFocus
      />
      <TextInput
        control={control}
        errors={errors}
        data-testid="lastName"
        name="lastName"
        label="Last Name"
        isRequired
      />
    </>
  );
};

export default IssuerFormUserStep;
