import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form-v6';
import { MultilineInput, TextInput } from '@nordictrustee/nt-ui-library';
import { IssuerAddFormFields } from 'modules/Issuers/Issuers.types';
import { helperText } from 'utils/reactHookFormUtils';

interface Props {
  formContent?: IssuerAddFormFields;
}

const IssuerFormGroupStep = ({ formContent }: Props) => {
  const methods = useFormContext();
  const { errors, control, reset } = methods;

  const remarkValue = useWatch({
    control,
    name: 'remark',
    defaultValue: '',
  });

  useEffect(() => {
    reset(formContent);
  }, [formContent, reset]);

  return (
    <>
      <TextInput
        control={control}
        errors={errors}
        data-testid="groupName"
        name="groupName"
        label="Name"
        autoFocus
        isRequired
      />
      <MultilineInput
        control={control}
        name="remark"
        data-testid="remark"
        rows={6}
        label="Remark"
        value={remarkValue}
        errorMessage={helperText('remark', errors)}
      />
    </>
  );
};

export default IssuerFormGroupStep;
