import React, { ReactNode } from 'react';
import { useMsal } from '@azure/msal-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { MenuButton } from '@nordictrustee/nt-ui-library';
import { EnvironmentName } from 'utils/types/EnvironmentName';
import EnvironmentChip from '../EnvironmentChip';
import Logo from './components/Logo';
import * as S from './Header.css';

interface Props {
  isAuthenticated: boolean;
  children?: ReactNode;
}

const { ENVIRONMENT_NAME } = window._env_;

const Header = (props: Props) => {
  const { instance } = useMsal();

  const handleLogOut = () => instance.logoutRedirect();

  return (
    <S.Header>
      <S.HeaderToolbar>
        {props.isAuthenticated ? (
          <>
            <S.LeftToolbar>
              <S.MainMenuBox>
                <Logo />
              </S.MainMenuBox>
              {ENVIRONMENT_NAME &&
                ENVIRONMENT_NAME !== EnvironmentName.prod && (
                  <EnvironmentChip environmentName={ENVIRONMENT_NAME} />
                )}
            </S.LeftToolbar>
            <S.RightToolbar>
              <MenuButton transformX={-10} icon={<AccountCircleIcon />}>
                <S.MenuItem
                  data-testid="logout-menuitem"
                  onClick={handleLogOut}
                >
                  Logout
                </S.MenuItem>
              </MenuButton>
            </S.RightToolbar>
          </>
        ) : (
          <Logo />
        )}
      </S.HeaderToolbar>
      {props.children}
    </S.Header>
  );
};

export default Header;
