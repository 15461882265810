import { Redirect, Route, Switch } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import Layout from 'components/Layout';
import { IssuersProvider } from 'modules/Issuers/IssuersContext';
import IssuerData from 'modules/Issuers/screens/IssuerData';
import Login from 'modules/Login';
import * as URL from './url';

const IssuersRoute = IssuerData;

export const Routes = ()=> (
  <Layout>
    <UnauthenticatedTemplate>
      <Route path={URL.URL_LOGIN}>
        <Login />
      </Route>
    </UnauthenticatedTemplate>
    <AuthenticatedTemplate>
      <Switch>
        <Route exact path={URL.ISSUERS}>
          <IssuersProvider>
            <IssuersRoute />
          </IssuersProvider>
        </Route>
        <Redirect to={URL.ISSUERS} />
      </Switch>
    </AuthenticatedTemplate>
  </Layout>
);
