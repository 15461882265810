import { useContext, useEffect, useMemo } from 'react';
import { Column } from '@material-table/core';
import { useMediaQuery } from '@mui/material';
import {
  BoxEmpty,
  ChipList,
  Table,
} from '@nordictrustee/nt-ui-library';
import theme from 'themes/theme';
import { User } from 'modules/Issuers/Issuers.types';
import { IssuersContext } from 'modules/Issuers/IssuersContext';
import { firstColPadding } from 'utils/constants';
import { ActionsGeneric } from 'utils/types/ActionsGeneric';
import AxiosPromiseGeneric from 'utils/types/AxiosPromiseGeneric';

interface Props {
  actions: ActionsGeneric<User>;
  showAllForIssuer: boolean;
  usersData: User[];
  isLoadingUsers: boolean;
  getUsers: AxiosPromiseGeneric<User[]>;
}

const UsersTable = ({
  actions,
  showAllForIssuer,
  usersData,
  isLoadingUsers,
  getUsers,
}: Props) => {
  const isBelowLargeScreenSize = useMediaQuery(theme.breakpoints.down('lg'));
  const { issuerCompanyFunctionID, groupID } = useContext(IssuersContext);

  useEffect(() => {
    if (issuerCompanyFunctionID > 0) {
      getUsers();
    }
  }, [getUsers, issuerCompanyFunctionID]);

  const filterUsersOnGroupID = useMemo(() => {
    return usersData.filter((user) =>
      user.groupList.some((group) => group.id === groupID),
    );
  }, [groupID, usersData]);

  const filteredUsersData = showAllForIssuer ? usersData : filterUsersOnGroupID;

  const columns: Column<User>[] = [
    {
      field: 'username',
      title: 'Username',
      width: '20%',
      render: ({ username }) => (
        <Table.ColumnWithTooltip title={username}>{username}</Table.ColumnWithTooltip>
      ),
      cellStyle: { ...firstColPadding.cellStyle, width: '300px' },
      headerStyle: { ...firstColPadding.headerStyle },
    },
    {
      field: 'firstName',
      title: 'First Name',
      width: '15%',
      render: ({ firstName }) => (
        <Table.ColumnWithTooltip title={firstName}>{firstName}</Table.ColumnWithTooltip>
      ),
    },
    {
      field: 'lastName',
      title: 'Last Name',
      width: '15%',
      render: ({ lastName }) => (
        <Table.ColumnWithTooltip title={lastName}>{lastName}</Table.ColumnWithTooltip>
      ),
    },
    {
      field: 'groups',
      title: 'Groups',
      width: 'calc(30% - 130px)',
      render: ({ groupList }) => (
        <ChipList
          customMaxLabelLength={groupList.length > 1 ? 6 : 15}
          list={groupList.map((group) => group.name)}
          maxChips={isBelowLargeScreenSize ? 1 : 2}
        />
      ),
    },
    {
      field: 'inviteSent',
      title: 'Invite Sent',
      width: '15%',
      render: ({ inviteSentDate }) => (
        <Table.ColumnWithTooltip title={inviteSentDate}>
          {inviteSentDate ?? 'N/A'}
        </Table.ColumnWithTooltip>
      ),
    },
  ];

  return issuerCompanyFunctionID ? (
    <Table.Root<User>
      columns={columns}
      data={filteredUsersData ?? []}
      isLoading={isLoadingUsers}
      disableDefaultSearchAndFilter
      options={{
        paging: false,
        search: false,
        toolbar: false,
        maxBodyHeight: '45.3vh',
        minBodyHeight: '45.3vh',
      }}
      actions={actions}
    />
  ) : (
    <BoxEmpty height="45.3vh" message="Select Issuer for the details" />
  );
};

export default UsersTable;
