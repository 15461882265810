import React, { useState } from 'react';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  ConfirmDialog,
  Container,
  SuccessToast,
} from '@nordictrustee/nt-ui-library';
import { Group, User } from 'modules/Issuers/Issuers.types';
import { handleException } from 'utils/errorHandlingUtils';
import { useToggle } from 'utils/hooks';
import AxiosPromiseGeneric from 'utils/types/AxiosPromiseGeneric';
import GroupForm from './components/GroupForm';
import GroupsTable from './components/GroupsTable';
import * as api from './api';
import { canDeleteGroup } from './utils';

interface Props {
  usersData: User[];
  getUsers: AxiosPromiseGeneric<User[]>;
  groupsData: Group[];
  isLoadingGroups: boolean;
  getGroups: AxiosPromiseGeneric<Group[]>;
}

const GroupList = ({
  usersData,
  getUsers,
  groupsData,
  isLoadingGroups,
  getGroups,
}: Props) => {
  const [editedItem, setEditedItem] = useState<Group>();
  const [editMode, setEditMode] = useState(false);
  const [deletedId, setDeletedId] = useState<number>();

  const [
    isGroupFormOpen,
    handleOpenGroupForm,
    handleCloseGroupForm,
  ] = useToggle();

  const [
    isDeleteDialogOpen,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
  ] = useToggle();

  const { isLoadingDeleteGroup, deleteGroup } = api.useDeleteGroup(deletedId!);

  const handleEditOpen = (data: Group) => {
    setEditedItem(data);
    setEditMode(true);
    handleOpenGroupForm();
  };

  const handleAddOpen = () => {
    setEditMode(false);
    handleOpenGroupForm();
  };

  const handleDeleteConfirmOpen = (id: number) => {
    setDeletedId(id);
    handleOpenDeleteDialog();
  };

  const handleDelete = async () => {
    try {
      await deleteGroup();
      getGroups();
      getUsers();
      handleCloseDeleteDialog();
      toast.success(<SuccessToast message="Group has been deleted" />);
    } catch (e: any) {
      handleException(e);
    }
  };

  return (
    <>
      <Container
        title="Groups"
        headerControls={
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddOpen}
            data-testid="add-group-button"
          >
            Add Group
          </Button>
        }
      >
        <GroupsTable
          actions={[
            (data) => ({
              icon: () => <EditIcon color="action" />,
              tooltip: 'Edit',
              onClick: () => handleEditOpen(data),
            }),
            (data) =>
              canDeleteGroup(data.users, usersData)
                ? {
                    icon: () => <DeleteIcon />,
                    tooltip: 'Delete',
                    onClick: () => handleDeleteConfirmOpen(data.id),
                  }
                : {
                    icon: () => <DeleteIcon />,
                    tooltip:
                      'Users needs to be deleted or reassigned before deleting group',
                    onClick: () => handleDeleteConfirmOpen(data.id),
                    disabled: true,
                  },
          ]}
          groupsData={groupsData}
          isLoadingGroups={isLoadingGroups}
          getGroups={getGroups}
        />
      </Container>
      {isGroupFormOpen && (
        <GroupForm
          handleClose={handleCloseGroupForm}
          editMode={editMode}
          group={editedItem}
          getGroups={getGroups}
          usersData={usersData}
          getUsers={getUsers}
        />
      )}
      {isDeleteDialogOpen && (
        <ConfirmDialog
          open={isDeleteDialogOpen}
          message="Do you want to delete this group?"
          submitText="Delete"
          onCancel={handleCloseDeleteDialog}
          onSubmit={handleDelete}
          isSubmitDisabled={isLoadingDeleteGroup}
        />
      )}
    </>
  );
};

export default GroupList;
