import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Button, Dialog } from '@nordictrustee/nt-ui-library';
import ItemSelector from 'components/ItemSelector/ItemSelector';
import { User, UserGroupWithIssuer } from 'modules/Issuers/Issuers.types';

interface Props {
  open: boolean;
  onSubmit: (user: User, userGroupsToRemove: UserGroupWithIssuer[]) => void;
  onCancel: () => void;
  user: User | undefined;
  issuerCompanyFunctionID: number;
}

const UsersDeleteForm = ({
  open,
  user,
  issuerCompanyFunctionID,
  onSubmit,
  onCancel,
}: Props) => {
  const [groupsToRemove, setGroupsToRemove] = useState<UserGroupWithIssuer[]>(
    [],
  );
  const [groupsToKeep, setGroupsToKeep] = useState<UserGroupWithIssuer[]>([]);

  useEffect(() => {
    const initialGroups = user?.groupList.filter(
      (g) => g.issuerCompanyFunctionID === issuerCompanyFunctionID,
    );
    const groupsToKeep = user?.groupList.filter(
      (g) => g.issuerCompanyFunctionID !== issuerCompanyFunctionID,
    );
    setGroupsToRemove(initialGroups || []);
    setGroupsToKeep(groupsToKeep || []);
  }, [issuerCompanyFunctionID, user, user?.groupList]);

  return (
    <Dialog
      title={`Remove group access (${user?.username})`}
      open={open}
      maxWidth="md"
      fullWidth
      onClose={() => onCancel()}
      dialogActions={
        <>
          <Button
            onClick={() => onSubmit(user!, groupsToRemove)}
            data-testid="save-button"
            disabled={!groupsToRemove || groupsToRemove.length === 0}
          >
            Remove Groups For User
          </Button>
          {groupsToKeep.length === 0 && (
            <Typography color="error">
              Warning: Removing all groups will also delete user!
            </Typography>
          )}
        </>
      }
    >
      <ItemSelector
        unselectedTitle="Groups to keep"
        unselected={groupsToKeep}
        selectedTitle="Groups to remove"
        selected={groupsToRemove}
        setUnselectItem={setGroupsToKeep}
        setSelectItem={setGroupsToRemove}
      />
    </Dialog>
  );
};

export default UsersDeleteForm;
