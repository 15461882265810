import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form-v6';
import { MultiSelect, TextInput, Tooltip } from '@nordictrustee/nt-ui-library';
import { Group, UserFormFields } from 'modules/Issuers/Issuers.types';
import { checkIfErrors } from 'utils/reactHookFormUtils';

interface Props {
  formContent?: UserFormFields;
  groups: Group[];
}

const UserFormInfoStep = ({ formContent, groups }: Props) => {
  const methods = useFormContext();
  const { errors, control, reset } = methods;

  useEffect(() => {
    reset({
      ...formContent,
      groupList: formContent?.groupList,
      groupIDs:
        formContent && formContent.groupList
          ? formContent.groupList.map((g) => g.id)
          : [],
    });
  }, [formContent, reset]);

  const selectedGroups = useMemo(() => {
    return formContent && formContent.groupList
      ? formContent.groupList.map((g) => ({
          label: g.name,
          value: { id: g.id, name: g.name },
          isFixed: true,
        }))
      : [];
  }, [formContent]);

  const issuerGroupOptions = useMemo(
    () =>
      groups
        .filter((g) => !formContent?.groupList.find((h) => h.id === g.id))
        .map((group) => ({
          label: group.name,
          value: { id: group.id, name: group.name },
        })),
    [formContent?.groupList, groups],
  );

  const groupOptions = useMemo(
    () => [...(selectedGroups || []), ...(issuerGroupOptions || [])],
    [issuerGroupOptions, selectedGroups],
  );

  return (
    <>
      <TextInput
        control={control}
        errors={errors}
        data-testid="firstName"
        name="firstName"
        label="First Name"
        autoFocus
        isRequired
      />
      <TextInput
        control={control}
        errors={errors}
        data-testid="lastName"
        name="lastName"
        label="Last Name"
        isRequired
      />
      <Tooltip
        title="To remove groups, use the delete button on user"
        placement="bottom-start"
      >
        <span>
          <MultiSelect
            control={control}
            name="groupList"
            options={groupOptions}
            label="Groups"
            data-testid="groups"
            menuPlacement="top"
            error={checkIfErrors('groupIDs', errors)}
            isClearable={selectedGroups.filter((g) => g.isFixed).length <= 0}
            isSearchable
            isRequired
          />
        </span>
      </Tooltip>
    </>
  );
};

export default UserFormInfoStep;
