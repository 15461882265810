import { ISSUERS, USER_MANAGEMENT } from 'router/url';
import { IssuersResult } from 'modules/Issuers/Issuers.types';
import { useAxios } from 'utils/hooks/useAxios';
import { PageableResponse } from 'utils/types/PageableResponse';

export const useGetAssignedIssuers = (
  page: number,
  rowsPerPage: number,
  filterString?: string,
) => {
  const [
    { data: assignedIssuersData, loading: isLoadingAssignedIssuers },
    getAssignedIssuers,
  ] = useAxios<PageableResponse<IssuersResult[]>>(
    `${USER_MANAGEMENT}/assigned-issuers?page=${page}&pageSize=${rowsPerPage}&filterString=${filterString}`,
    { manual: true },
  );
  return {
    assignedIssuersData,
    isLoadingAssignedIssuers,
    getAssignedIssuers,
  };
};

export const useGetIssuers = (filterString?: string) => {
  const [
    { data: issuersData, loading: isLoadingIssuers },
    getIssuers,
  ] = useAxios<PageableResponse<IssuersResult[]>>(
    `${USER_MANAGEMENT}/issuers?page=0&pageSize=15&filterString=${filterString}`,
    { manual: true },
  );
  return {
    issuersData,
    isLoadingIssuers,
    getIssuers,
  };
};

export const usePostIssuer = () => {
  const [, postIssuer] = useAxios(
    {
      url: `${USER_MANAGEMENT}${ISSUERS}`,
      method: 'POST',
    },
    { manual: true },
  );
  return { postIssuer };
};
