import { v4 as uuidv4 } from 'uuid';

export const getBrowserSessionId = (browserSessionHeaderName: string) => {
  const storageId = sessionStorage.getItem(browserSessionHeaderName);
  if (storageId) {
    return storageId;
  }
  const newSessionId = uuidv4();
  sessionStorage.setItem(browserSessionHeaderName, newSessionId);
  return newSessionId;
};

