import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form-v6';
import { Select } from '@nordictrustee/nt-ui-library';
import { IssuerAddFormFields } from 'modules/Issuers/Issuers.types';
import useDebounce from 'utils/hooks/useDebounce';
import { checkIfErrors } from 'utils/reactHookFormUtils';
import * as api from '../../../api';

type Props = {
  formContent?: IssuerAddFormFields;
};

export const IssuerFormIssuerStep = ({ formContent }: Props) => {
  const methods = useFormContext();
  const { reset, control, errors } = methods;
  const [issuerSearchText, setIssuerSearchText] = useState('');

  const searchQuery = useDebounce(issuerSearchText, 300);

  const { issuersData, isLoadingIssuers, getIssuers } = api.useGetIssuers(
    searchQuery,
  );

  useEffect(() => {
    reset(formContent);
  }, [formContent, reset]);

  useEffect(() => {
    getIssuers();
  }, [getIssuers]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIssuerSearchText(event?.target?.value ?? '');
  };

  const assignableSelectOptions = useMemo(
    () =>
      issuersData?.results.filter((issuer) => issuer.isActive).map((issuer) => ({
        label: issuer.name,
        value: issuer.id,
      })),
    [issuersData?.results],
  );

  return (
    <Select
      control={control}
      name="issuerCompanyFunctionID"
      options={!isLoadingIssuers ? assignableSelectOptions : []}
      label="Issuer"
      data-testid="issuer"
      onInput={handleInputChange}
      error={checkIfErrors('issuerCompanyFunctionID', errors)}
      maxMenuHeight={170}
      helperText="Search for issuer"
      isClearable
      isRequired
      autoFocus
    />
  );
};

export default IssuerFormIssuerStep;
