import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-v6';
import { toast } from 'react-toastify';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import {
  Button,
  Dialog,
  OutlinedButton,
  SuccessToast,
} from '@nordictrustee/nt-ui-library';
import { Group, User, UserFormFields } from 'modules/Issuers/Issuers.types';
import { confirmClose } from 'utils/confirmClose';
import { handleException } from 'utils/errorHandlingUtils';
import AxiosPromiseGeneric from 'utils/types/AxiosPromiseGeneric';
import * as issuerDataApi from '../../../../api';
import * as api from '../../api';
import UserFormInfoStep from './components/UserFormInfoStep';
import UserFormUsernameStep from './components/UserFormUsernameStep';
import * as S from './UserStepper.css';

type Props = {
  open: boolean;
  onClose: () => void;
  groups: Group[];
  getGroups: AxiosPromiseGeneric<Group[]>;
  getUsers: AxiosPromiseGeneric<User[]>;
};

const stepsLabels = ['Add Username', 'Add User'];

const UserStepper = ({ open, onClose, groups, getGroups, getUsers }: Props) => {
  const methods = useForm({ mode: 'onBlur' });
  const { watch, errors, handleSubmit, formState } = methods;

  const [activeStep, setActiveStep] = useState(0);
  const [isStepperDirty, setStepperDirty] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [compiledForm, setCompiledForm] = useState<UserFormFields>({
    id: 0,
    username: '',
    firstName: '',
    lastName: '',
    groupList: [],
    groupIDs: [],
  });

  const form = watch();

  const { postUser } = api.usePostUser();
  const { validateUser } = issuerDataApi.useValidateUser(form.username);

  const getStepContent = (step: number, formContent?: UserFormFields) => {
    switch (step) {
      case 0:
        return <UserFormUsernameStep formContent={formContent} />;
      case 1:
        return <UserFormInfoStep formContent={formContent} groups={groups} />;
      default:
        return 'Unknown step';
    }
  };

  useEffect(() => {
    //Select on the first step is required, so it's enough to make stepperDitry
    if (activeStep === 0) {
      setStepperDirty(formState.isDirty);
    }
  }, [activeStep, formState.isDirty]);

  const isNewUser = async () => {
    setIsLoadingNext(true);
    const result = await validateUser();

    const userInfo = result.data?.userInfo;
    setCompiledForm({
      ...compiledForm,
      username: form.username,
      firstName: userInfo ? userInfo.firstName : '',
      lastName: userInfo ? userInfo.lastName : '',
      groupList: userInfo
        ? userInfo.groupList.map((g) => ({
            id: g.id,
            name: g.name,
          }))
        : [],
      groupIDs: userInfo ? userInfo.groupList.map((u) => u.id) : [],
    });
    setIsLoadingNext(false);
    return true;
  };

  const handleNext = handleSubmit(async () => {
    let canContinue = true;
    switch (activeStep) {
      case 0:
        setCompiledForm({
          ...compiledForm,
          username: form.username,
        });
        canContinue = await isNewUser();
        break;
      case 1:
        setCompiledForm({
          ...compiledForm,
          firstName: form.firstName,
          lastName: form.lastName,
          groupList: form.groupList,
          groupIDs: form.groupIDs,
        });
        canContinue = false;
        onSave({
          ...compiledForm,
          firstName: form.firstName,
          lastName: form.lastName,
          groupList: form.groupList,
          groupIDs: form.groupIDs,
        });
        break;
      default:
        return 'not a valid step';
    }
    if (canContinue) {
      setActiveStep((prevActiveStep) => ++prevActiveStep);
    }
  });

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => --prevActiveStep);
      setCompiledForm(form as UserFormFields);
    }
  };

  const handleConfirmClose = () => {
    confirmClose(isStepperDirty, onClose);
  };

  useEffect(() => {
    if (open) {
      setActiveStep(0);
      setCompiledForm({
        id: 0,
        username: '',
        firstName: '',
        lastName: '',
        groupList: [],
        groupIDs: [],
      });
    }
  }, [open]);

  const onSave = async (data: UserFormFields) => {
    try {
      const formData: UserFormFields = {
        id: 0,
        username: data.username,
        firstName: data.firstName,
        lastName: data.lastName,
        groupList: data.groupList,
        groupIDs: data.groupIDs,
      };
      await postUser({ data: formData });
      getGroups();
      getUsers();
      toast.success(<SuccessToast message="User has been saved" />);
      onClose();
    } catch (e: any) {
      handleException(e);
    }
  };

  const continueDisabled = !!Object.values(errors).length;

  return (
    <Dialog
      open={open}
      onClose={handleConfirmClose}
      fullWidth
      maxWidth="sm"
      title="Add User"
      dialogActions={
        <>
          <Button
            onClick={handleNext}
            data-testid="continue-button"
            disabled={continueDisabled}
            isLoading={isLoadingNext}
          >
            {activeStep === stepsLabels.length - 1 ? 'save' : 'continue'}
          </Button>
          <OutlinedButton
            onClick={handleConfirmClose}
            data-testid="cancel-button"
          >
            cancel
          </OutlinedButton>
          <Button
            onClick={handleBack}
            data-testid="back-button"
            disabled={activeStep === 0}
          >
            back
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <S.Container>
          <Stepper activeStep={activeStep}>
            {stepsLabels.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box mt={2}>{getStepContent(activeStep, compiledForm)}</Box>
        </S.Container>
      </FormProvider>
    </Dialog>
  );
};

export default UserStepper;
